/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BlogCard from './../components/BlogCard';
import { NavLink } from 'react-router-dom';
import ScrollTop from '../components/ScrollTop';

import { client } from './../client';

class ContentFulContents extends React.Component {
    state = {
        articles: []
    }

    componentDidMount() {
        client.getEntries()
            .then((response) => {
                // console.log(response);
                const items = response.items.slice(0, 6);
                this.setState({
                    articles: items
                })
                // console.log(this.state.articles);
            })
            .catch(console.error)
    }
    render() {
        const first = this.state.articles.length > 0 ? this.state.articles[0].fields : '';
        return (
            <>
                <section className="w-full items-center min-h-50vh mb-28">
                    {first ? (
                        <div className="flex w-full gap-10 max-w-6xl mx-auto px-4 sm:px-6">
                            <div className="w-60-per">
                                <img
                                    src={'https:' + first.featuredImage.fields.file.url}
                                    height={first.featuredImage.fields.file.details.image.height}
                                    width={first.featuredImage.fields.file.details.image.width}
                                    alt={first.title}
                                />
                            </div>
                            <div className="w-40-per flex flex-col justify-center">
                                <h2 className="flex gap-4 text-xs font-normal tracking-push subpixel-antialiased text-gray-light uppercase">{first.tag}</h2>
                                <div className="">
                                    <h3 className="text-green-light font-bold my-4 text-base md:text-3xl leading-22px subpixel-antialiased whitespace-normal">{first.title}</h3>
                                    <p className="text-gray-normal font-light text-sm md:text-base leading-22px subpixel-antialiased whitespace-normal">
                                        {first.content.substring(0, 150)}...<NavLink
                                            key={1}
                                            to={'/blog/' + first.slug}
                                        ><span className="cursor-pointer font-bold text-green-dark">read more</span></NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : ('')
                    }
                </section>
                <section className="flex flex-col min-h-50vh mb-20">
                    <div className="flex flex-wrap w-full gap-10 max-w-6xl mx-auto px-4 sm:px-6">
                        {this.state.articles.length > 0 ?
                            (
                                <>
                                    {this.state.articles.map((item, index) => (
                                        <>
                                            {index !== 0 ? (
                                                <BlogCard item={item} key={index} />
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ))}
                                </>
                            ) : ('')
                        }
                    </div>
                </section>
            </>
        );
    }
}

function blog() {
    return (
        <>
            <ScrollTop />
            <section className="w-full pt-28 pb-20">
                <div className="flex max-w-6xl mx-auto px-4 sm:px-6 justify-end">
                    <div className="flex flex-col my-8 w-70-per">
                        <h1 className="text-2xl md:text-4xl font-black leading-22px md:leading-12 subpixel-antialiased text-green-light">The Sustyvibes Blog</h1>
                        <nav className="hidden md:block bg-green-brighter border-t border-b border-green-light w-full mt-12">
                            <ul className="flex gap-20 py-4 text-green-light">
                                <li className="blog-link"><a>News</a></li>
                                <li className="blog-link"><a>Environment</a></li>
                                <li className="blog-link"><a>Energy</a></li>
                                <li className="blog-link"><a>Women</a></li>
                                <li className="blog-link"><a>Diary</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <ContentFulContents />
        </>
    )
}

export default blog
