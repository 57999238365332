import React from 'react'
import shopBanner from './../assets/images/shop-banner.svg';
import BERN93662 from './../assets/images/BERN93662.png';
import IMG20181019WA00171 from './../assets/images/IMG-20181019-WA00171.png';
import policy from './../assets/svg/policy.svg';
import projects from './../assets/svg/projects.svg';
import products from './../assets/svg/products.svg';
import longArrow from './../assets/images/long-arrow.svg';

// components
import Partners from './../components/Partners'
import ScrollTop from '../components/ScrollTop';

function about() {
    return (
        <>
            <ScrollTop />
            {/* we are making section */}
            <section className="w-full bg-green-brighter pt-28 pb-20">
                <div className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex flex-col w-full max-w-3xl mx-auto px-4 sm:px-6">
                        <h1 className="text-2xl md:text-4xl font-black leading-22px md:leading-12 subpixel-antialiased text-green-light">Since 2016, We’ve Been <br /><span className="text-green-dark text-2xl md:text-4xl">Championing Climate Action</span> Across Africa</h1>
                    </div>
                </div>
            </section>
            {/* image section */}
            <section className="relative flex max-w-6xl mx-auto min-h-30vh md:min-h-50vh px-4 sm:px-6 mb-4 md:mb-32">
                <div className="absolute -top-12">
                    <img src={IMG20181019WA00171} alt="Children" />
                </div>
            </section>

            <section className="w-full py-20">
                <div className="flex flex-col w-full max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex flex-col w-full md:w-42rem max-w-2xl mx-auto px-4 sm:px-6">
                        <p className="text-gray-normal font-normal text-base leading-22px subpixel-antialiased whitespace-normal">
                            Since 2016, We’ve Been Championing Climate Action Across Africa Through School-based Educational Programs And Empowerment Projects.
                            <br /><br />
                            As a community, we’re committed to driving environmental and social sustainability forward through education and result-driven projects. We champion policy changes in Africa, identify and promote responsibly created products and facilitate eco-feminism and sustainability projects.
                            <br /><br />
                            As a social enterprise, we create sustainability plans for organisations looking to reduce waste and become more environmentally conscious. We also identify CSR opportunities for local and global organisations.
                        </p>
                    </div>
                </div>
            </section>

            <section className="flex items-center min-h-50vh">
                <div className="flex flex-col max-w-6xl mx-auto py-10 px-4 sm:px-6">
                    <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase">OUR VISION</h2>
                    <div className="flex md:grid flex-col md:grid-cols-2 my-4 gap-8 md:gap-24">
                        <p className="text-green-light font-bold leading-22px text-xl md:text-2xl md:leading-32px subpixel-antialiased whitespace-normal">We envision a world where we do not have to make a business case for protecting our future; where doing the right thing for the planet is normal.</p>
                        <p className="text-gray-normal font-light text-sm md:text-tiny leading-22px subpixel-antialiased whitespace-normal">
                            Our mission is to ensure the long-term safety of women, children and others most vulnerable to the dangers of climate change. We’re achieving this by equipping young people with the knowledge and tools needed for eco-friendly living, while empowering and advocating for the most affected people and communities
                        </p>
                    </div>
                </div>
            </section>
            <Partners />
            {/* image section */}
            <section className="flex flex-col max-w-6xl mx-auto my-20 px-4 sm:px-6 mt-16">
                {/* <HomeSlider /> */}
                <img src={BERN93662} alt="Team" />
            </section>
            <section className="flex flex-col items-center my-20">
                <div className="flex flex-col full-width w-42rem max-w-2xl justify-start mx-auto px-4 sm:px-6">
                    <p className="text-xs md:text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4 align-left">Our objectives</p>
                    <h2 className="text-green-light font-bold leading-20px md:leading-24px text-base md:text-2xl subpixel-antialiased whitespace-normal">We believe in these values and have them at the core of everything we do at SustyVibes</h2>
                </div>
            </section>
            <section className="flex items-center min-h-50vh mb-20">
                <div className="flex flex-wrap w-full gap-10 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-40-per md:w-25-per flex flex-col">
                        <img src={products} className="w-20-per my-4" alt="Products" />
                        <h2 className="text-green-light font-bold leading-20px text-base md:text-base subpixel-antialiased whitespace-normal mb-2">Products</h2>
                        <p className="text-gray-normal font-light text-sm md:text-tiny leading-20px subpixel-antialiased whitespace-normal mb-4">We genuinely care about sustainability and the message of protecting the earth.</p>
                    </div>
                    <div className="w-40-per md:w-25-per flex flex-col">
                        <img src={policy} className="w-20-per my-4" alt="Policy" />
                        <h2 className="text-green-light font-bold leading-20px text-base md:text-base subpixel-antialiased whitespace-normal mb-2">Policy</h2>
                        <p className="text-gray-normal font-light text-sm md:text-tiny leading-20px subpixel-antialiased whitespace-normal mb-4">We liaise with the public sector in Nigeria to get environmentally hazardous policies reexamined and eradicated.</p>
                    </div>
                    <div className="w-40-per md:w-25-per flex flex-col">
                        <img src={projects} className="w-20-per my-4" alt="Projects" />
                        <h2 className="text-green-light font-bold leading-20px text-base md:text-base subpixel-antialiased whitespace-normal mb-2">Projects</h2>
                        <p className="text-gray-normal font-light text-sm md:text-tiny leading-20px subpixel-antialiased whitespace-normal mb-4">We work with schools and community centers to form susty-clubs where we teach sustainability.</p>
                    </div>
                </div>
            </section>
            <section className="flex items-center mb-32 h-10vh md:h-40vh">
                <div className="flex flex-wrap w-full relative gap-10 max-w-6xl mx-auto px-4 sm:px-6 justify-center">
                    <img src={shopBanner} alt="sustyvibes shop banner" />
                    <div className="flex justify-between absolute w-full md:w-42rem max-w-5xl mx-auto h-10vh md:h-30vh items-center">
                        <div className="w-full md:w-60-per">
                            <h4 className="font-bold text-xs md:text-2xl subpixel-antialiased whitespace-normal text-green-bright mb-4">Join our community</h4>
                            <p className="text-green-bright font-normal text-xs md:text-base subpixel-antialiased whitespace-normal">Want to volunteer to be a Sustyviber?</p>
                        </div>
                        <a href="https://docs.google.com/forms/d/1BjgZ21tbTWZv8PRluBwTJBLeojch5akDDRPAHon9lFM/edit?ts=60c63fa9" target="_blank" className="flex flex-col md:flex-row gap-0 md:gap-8 w-full md:w-60-per justify-end" rel="noreferrer"><span className="font-bold text-base md:text-2xl subpixel-antialiased whitespace-normal text-green-light">Join us</span> <img src={longArrow} alt="long arrow" className="w-15-per md:w-full" /></a>
                    </div>
                </div>
            </section>
            {/* ruler */}
            {/* <div className="flex w-full h-20">
                <div className="full-width max-w-6xl mx-auto px-4 sm:px-6">
                    <hr className="border border-green-light" />
                </div>
            </div>
            <section className="flex flex-col items-center my-20">
                <div className="flex flex-col full-width w-42rem max-w-2xl justify-start mx-auto px-4 sm:px-6">
                    <p className="text-xs md:text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4 align-left">Leadership</p>
                    <h2 className="text-green-light font-bold leading-20px md:leading-24px text-base md:text-2xl subpixel-antialiased whitespace-normal">Our work is broadly categorized into three types of projects.</h2>
                </div>
            </section>
            <section className="flex flex-col min-h-50vh mb-32">
                <div className="flex flex-wrap w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-full md:w-27-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-27-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-27-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png " className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                    <div className="w-40-per md:w-23-per my-4 h-55vh">
                        <div className="relative">
                            <img src="/images/blog.png" className="w-full" alt="blog image" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-base md:text-xl subpixel-antialiased whitespace-normal mt-4">Jennifer Uchendu</h3>
                            <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-2 tracking-push uppercase">Founder</p>
                            <p className="text-gray-normal font-light text-sm md:text-base leading-20px subpixel-antialiased whitespace-normal mt-4">
                                We are constantly learning and researching so we can share and teach the world more about sustainability. We are constantly learning and researching so we can share and teach the world more about sustainability.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default about
