import React from 'react'
import downArrow from './../assets/images/arrow-down.svg';
import line from './../assets/images/line.svg';
import WgQAIUc1 from './../assets/images/WgQAIU-c1.png';
import sustainable from './../assets/images/sustainable.png';
import pop from './../assets/images/pop.png';
import business from './../assets/images/business.png';
import pollution from './../assets/images/pollution.png';
import women from './../assets/images/women.png';
import ScrollTop from '../components/ScrollTop';

function projects() {
    return (
        <>
            <ScrollTop />
            <section className="w-full bg-green-brighter pt-28 pb-32">
                <div className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex max-w-4xl mx-auto">
                        <h1 className="text-2xl md:text-4xl font-black leading-32px subpixel-antialiased text-green-light">Check out challenges we have solved so far</h1>
                    </div>
                    <div className="flex my-8 max-w-4xl">
                        <img src={downArrow} alt="arrow down" className="mr-8 md:mr-40" />
                        <p className="text-gray-normal font-light text-sm md:text-base leading-16px subpixel-antialiased whitespace-normal">Our talented volunteers and their team leads organize events and projects to achieve the goals of these 5 pillars. </p>
                    </div>
                </div>
            </section>
            {/* image section */}
            <section className="relative flex max-w-6xl mx-auto min-h-50vh px-0 sm:px-6">
                <div className="absolute -top-12">
                    <img src={WgQAIUc1} alt="Slider" />
                </div>
            </section>
            {/* sections */}
            <section className="w-full flex flex-col md:grid md:grid-cols-2 pt-0 pb-12 md:py-28 gap-24 mt-8 items-center min-h-50vh">
                <div className="px-12 md:px-24">
                    <h2 className="flex gap-4 text-xs font-normal tracking-push subpixel-antialiased text-gray-light uppercase"><img src={line} alt="line svg" /> SustySchools</h2>
                    <div className="">
                        <h3 className="text-green-light font-bold my-4 text-2xl leading-20px subpixel-antialiased whitespace-normal">Education for Sustainable Development (ESD)</h3>
                        <p className="text-gray-normal font-light text-base leading-20px subpixel-antialiased whitespace-normal">
                            The ESD team works with schools, universities and other educational institutions to share experiences and knowledge about Sustainable Development. So far, the team has taught over 250 children and educators in Lagos, Abuja and Port Harcourt.
                            <br /><br />
                            Our SustySchools team is a subsidiary of the ESD team that focuses on creating fun and entertaining ways to show how to implement sustainable development practices.
                        </p>
                    </div>
                </div>
                <div className="">
                    <img src={sustainable} alt="Education for Sustainable Development (ESD)" />
                </div>
            </section>

            <section className="w-full flex flex-col md:grid md:grid-cols-2 bg-green-brighter py-28 gap-24 items-center min-h-50vh">
                <div className="">
                    <img src={pollution} alt="Combating Pollution" />
                </div>
                <div className="px-12 md:px-24">
                    <h2 className="flex gap-4 text-xs font-normal tracking-push subpixel-antialiased text-gray-light uppercase"><img src={line} alt="line svg" /> Street Conferences</h2>
                    <div className="">
                        <h3 className="text-green-light font-bold my-4 text-2xl leading-20px subpixel-antialiased whitespace-normal">Combating Pollution</h3>
                        <p className="text-gray-normal font-light text-base leading-20px subpixel-antialiased whitespace-normal">
                            With our Street Conferences, championed by our Susty On The Streets volunteers, we dedicate time once a month to discuss waste management with Nigerians on the street. The Street Conference is a day of clean ups and conversations to encourage better waste management practices.
                            <br /> <br />
                            To ensure proper implementation, we often partner with recycling and waste management companies and encourage our new eco converts to sign up with them.
                        </p>
                    </div>
                </div>
            </section>

            <section className="w-full flex flex-col md:grid md:grid-cols-2 py-28 gap-24 items-center min-h-50vh">
                <div className="px-12 md:px-24">
                    <h2 className="flex gap-4 text-xs font-normal tracking-push subpixel-antialiased text-gray-light uppercase"><img src={line} alt="line svg" /> Street Dreams Project</h2>
                    <div className="">
                        <h3 className="text-green-light font-bold my-4 text-2xl leading-20px subpixel-antialiased whitespace-normal">Women's Empowerment</h3>
                        <p className="text-gray-normal font-light text-base leading-20px subpixel-antialiased whitespace-normal">
                            Around the world, the effects of climate change affect women disproportionately. The quality of education and opportunities societies allow women is one of the reasons for this.
                            <br /><br />
                            At SustyVibes, one of our core goals is to equip women with the power and opportunities to close this vulnerability gap. With partnerships and projects focusing directly on women, we contribute to creating opportunities for young women in Nigeria
                        </p>
                    </div>
                </div>
                <div className="">
                    <img src={women} alt="Women's Empowerment" />
                </div>
            </section>

            <section className="bg-green-brighter w-full flex flex-col md:grid md:grid-cols-2 py-28 gap-24 items-center min-h-50vh">
                <div className="">
                    <img src={pop} alt="Pop Culture for Sustainable Living" />
                </div>
                <div className="px-12 md:px-24">
                    <h2 className="flex gap-4 text-xs font-normal tracking-push subpixel-antialiased text-gray-light uppercase"><img src={line} alt="line svg" /> Street Dreams Project</h2>
                    <div className="">
                        <h3 className="text-green-light font-bold my-4 text-2xl leading-20px subpixel-antialiased whitespace-normal">Pop Culture for Sustainable Living</h3>
                        <p className="text-gray-normal font-light text-base leading-20px subpixel-antialiased whitespace-normal">
                            We understand that the youths are one of our major target audiences so we switch things up by infusing popular culture into our advocacy efforts to create a fun, relaxed and interesting learning environment for them.
                        </p>
                    </div>
                </div>
            </section>

            <section className="w-full flex flex-col md:grid md:grid-cols-2 py-28 gap-24 items-center min-h-50vh">
                <div className="px-12 md:px-24">
                    <h2 className="flex gap-4 text-xs font-normal tracking-push subpixel-antialiased text-gray-light uppercase"><img src={line} alt="line svg" /> Green business pillar</h2>
                    <div className="">
                        <h3 className="text-green-light font-bold my-4 text-2xl leading-20px subpixel-antialiased whitespace-normal">Sustainable Business & The Green Economy</h3>
                        <p className="text-gray-normal font-light text-base leading-20px subpixel-antialiased whitespace-normal">
                            SustyVibes is positioned to advice, mentor and train entrepreneurs to build sustainable businesses that impact the triple bottom line (People, Planet, Profit)
                            <br /><br />
                            The Green Business Pillar is an education tool. Our aim is to encourage young Nigerians to pursue green careers, show young entrepreneurs the benefits of the triple bottom line business strategy and generally contribute to the youth knowledge pool.
                        </p>
                    </div>
                </div>
                <div className="">
                    <img src={business} alt="Sustainable Business & The Green Economy" />
                </div>
            </section>
        </>
    )
}

export default projects
