import React from 'react';
import { Route } from 'react-router-dom';

// components
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// pages
import Home from './pages/home';
import Projects from './pages/projects';
import About from './pages/about';
import Shop from './pages/shop';
import Blog from './pages/blog';
import SinglePage from './pages/singlepage';
import Recycle from './pages/recycle';
import RecyclePage from './pages/recyclepage';
import Volunteer from './pages/volunteer';

// routes config
const routes = [
  { path: '/', name: 'Home', Component: Home },
  { path: '/projects', name: 'Projects', Component: Projects },
  { path: '/about', name: 'About', Component: About },
  { path: '/shop', name: 'Shop', Component: Shop },
  { path: '/blog', name: 'Blog', Component: Blog },
  { path: '/blog/:slug', name: 'Singlepage', Component: SinglePage },
  { path: '/recycle', name: 'Recycle', Component: Recycle },
  { path: '/recycle/:id', name: 'RecyclePage', Component: RecyclePage },
  { path: '/volunteer-with-us', name: 'Volunteer', Component: Volunteer }
];

function App() {
  return (
    <>
      <Navbar />
      <main className="w-full">
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            <Component />
          </Route>
        ))}
      </main>
      <Footer />
    </>
  );
}

export default App;
