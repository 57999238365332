/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import smallArrow from './../assets/images/small-arrow.svg';
import leftArrow from './../assets/images/arrow-left.svg';
import rightArrow from './../assets/images/arrow-right.svg';
import knowledge from './../assets/images/knowledge.png';
import design from './../assets/images/design.png';
import research from './../assets/images/research.png';

const CardCarousel = () => {
    return (
        <CarouselProvider
            visibleSlides={3}
            totalSlides={3}
            naturalSlideWidth={400}
            naturalSlideHeight={550}
        >
            <div className="flex gap-10 mb-10 px-32">
                <ButtonBack className="outline-white"><img src={leftArrow} alt="arrow left" /></ButtonBack>
                <ButtonNext className="outline-white"><img src={rightArrow} alt="arrow right" /></ButtonNext>
            </div>
            <Slider>
                <Slide tag="div" index={0} className="carouselSlide">
                    <div className="w-20rem">
                        <img src={knowledge} className="" alt="knowledge" />
                        <div className="w-full flex flex-col py-4 px-10 border-b border-l border-r border-green-light">
                            <h3 className="text-green-light font-bold text-base leading-22px subpixel-antialiased whitespace-normal mt-4">Knowledge</h3>
                            <p className="text-gray-normal font-light text-tiny leading-22px subpixel-antialiased whitespace-normal my-4">We believe that awareness is critical to the advancement and onward mass adoption of sustainable practices in Africa . We seek to expand...</p>
                            <NavLink to="/projects"><a className="flex items-center gap-4 text-green-light font-sm text-tiny subpixel-antialiased whitespace-normal">Find out more <img src={smallArrow} alt="arrow-right" /></a></NavLink>
                        </div>
                    </div>
                </Slide>
                <Slide tag="div" index={1}>
                    <div className="w-20rem">
                        <img src={design} className="" alt="design" />
                        <div className="w-full flex flex-col py-4 px-10 border-b border-l border-r border-green-light">
                            <h3 className="text-green-light font-bold text-base leading-22px subpixel-antialiased whitespace-normal mt-4">Design</h3>
                            <p className="text-gray-normal font-light text-tiny leading-22px subpixel-antialiased whitespace-normal my-4">Through experimentation and collaboration we design projects aimed at creating a culture of environmental awareness. We work with...</p>
                            <NavLink to="/projects"><a className="flex items-center gap-4 text-green-light font-sm text-tiny subpixel-antialiased whitespace-normal">Find out more <img src={smallArrow} alt="arrow-right" /></a></NavLink>
                        </div>
                    </div>
                </Slide>
                <Slide tag="div" index={2}>
                    <div className="w-20rem">
                        <img src={research} className="" alt="research" />
                        <div className="w-full flex flex-col py-4 px-10 border-b border-l border-r border-green-light">
                            <h3 className="text-green-light font-bold text-base leading-22px subpixel-antialiased whitespace-normal mt-4">Research</h3>
                            <p className="text-gray-normal font-light text-tiny leading-22px subpixel-antialiased whitespace-normal my-4">We are bridging the sustainability research gap in Africa through data collation and analysis. Our research team is made up of people...</p>
                            <NavLink to="/projects"><a className="flex items-center gap-4 text-green-light font-sm text-tiny subpixel-antialiased whitespace-normal">Find out more <img src={smallArrow} alt="arrow-right" /></a></NavLink>
                        </div>
                    </div>
                </Slide>
                {/* <Slide tag="div" index={3}>
                    <div className="w-20rem">
                        <img src={design} className="" alt="design" />
                        <div className="w-full flex flex-col py-4 px-10 border-b border-l border-r border-green-light">
                            <h3 className="text-green-light font-bold text-base leading-22px subpixel-antialiased whitespace-normal mt-4">Fun</h3>
                            <p className="text-gray-normal font-light text-tiny leading-22px subpixel-antialiased whitespace-normal my-4">We love fun, so we’ve incorporated it into our sustainability message through pop-culture. We plan fun ...</p>
                            <NavLink to="/projects"><a className="flex items-center gap-4 text-green-light font-sm text-tiny subpixel-antialiased whitespace-normal">Find out more <img src={smallArrow} alt="arrow-right" /></a></NavLink>
                        </div>
                    </div>
                </Slide> */}
            </Slider>
        </CarouselProvider>
    )
}

export default CardCarousel;