/* eslint-disable jsx-a11y/anchor-is-valid */
import react, { useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import logo from './../assets/images/logo.svg';
import hamburger from './../assets/images/hamburger.svg';

function Navbar() {
    const location = useLocation();
    let path = location.pathname;
    path = path.replace("/", "");
    // nav
    let blog = path.includes('blog') ? true : false;
    let shop = path.includes('shop') ? true : false;
    let recycle = path.includes('recycle') ? true : false;
    let recycleChild = path.includes('recycle/') ? true : false;
    let greenNav = 'bg-green-brighter';
    if (path === '' || blog || recycleChild) {
        greenNav = '';
    } else if (recycle) {
        greenNav = 'bg-green-dark'
    }
    const [mobileNav, setMobileNav] = useState(false);
    return (
        <>
            <div className={`relative ${greenNav}`}>
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center h-5rem md:h-32 border-b border-green-light md:justify-start md:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <NavLink to="/"><a>
                                <img src={logo} alt="Sustyvibes Logo" />
                            </a></NavLink>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none" aria-expanded="false" onClick={() => { setMobileNav(!mobileNav) }}>
                                <span className="sr-only">Open menu</span>
                                <img src={hamburger} alt="Hamburger" />
                            </button>
                        </div>
                        <ul className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10">
                            <li className={`cool-link ${path === 'about' ? 'active-link' : ''}`}><NavLink to="/about"><a className="text-sm font-hairline leading-5">
                                About Us
                            </a></NavLink></li>
                            <li className={`cool-link ${path === 'projects' ? 'active-link' : ''}`}><NavLink to="/projects"><a className="text-sm font-hairline leading-5">
                                Projects
                            </a></NavLink></li>
                            {/* <li className={`cool-link ${path === 'shop' ? 'active-link' : ''}`}><NavLink to="/shop"><a className="text-sm font-hairline leading-5">
                                Shop
                            </a></NavLink></li> */}
                            <li className={`cool-link ${blog ? 'active-link' : ''}`}><NavLink to="/blog"><a className="text-sm font-hairline leading-5">
                                Blog
                            </a></NavLink></li>
                            <a href="https://flutterwave.com/pay/sustyyvibesqpcv" target="_blank" className="bg-green-light text-sm font-normal text-white-light py-2 px-4 rounded-full" rel="noreferrer">
                                Donate Now
                            </a>
                        </ul>
                    </div>
                </div>
                {(mobileNav)
                    ? <div className="bg-green-brighter px-20 pb-8">
                        <ul className="flex flex-col ">
                            <li className={`cool-link ${path === 'about' ? 'active-link' : ''}`}><NavLink to="/about"><a className="text-sm font-hairline leading-5">
                                About Us
                            </a></NavLink></li>
                            <li className={`cool-link ${path === 'projects' ? 'active-link' : ''}`}><NavLink to="/projects"><a className="text-sm font-hairline leading-5">
                                Projects
                            </a></NavLink></li>
                            {/* <li className={`cool-link ${path === 'shop' ? 'active-link' : ''}`}><NavLink to="/shop"><a className="text-sm font-hairline leading-5">
                                Shop
                            </a></NavLink></li> */}
                            <li className={`cool-link ${blog ? 'active-link' : ''}`}><NavLink to="/blog"><a className="text-sm font-hairline leading-5">
                                Blog
                            </a></NavLink></li>
                            <a href="https://flutterwave.com/pay/sustyyvibesqpcv" target="_blank" className="bg-green-light text-sm font-normal text-white-light py-2 px-4 rounded-full text-center" rel="noreferrer">
                                Donate Now
                            </a>
                        </ul>
                    </div>
                    : ''
                }
            </div>
        </>
    )
}

export default Navbar
