/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import ScrollTop from '../components/ScrollTop';

import { client } from './../client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

class ContentFulContents extends React.Component {
    state = {
        article: {},
    }

    componentDidMount() {
        // const { match: { params } } = this.props;
        // console.log(this.props);
        // let { slug } = useParams()
        client.getEntries()
            .then((response) => {
                // console.log(response);
                const blog = response.items.filter((value, index) => value.fields.slug === this.props.slug);
                this.setState({
                    article: blog[0].fields
                })
                // console.log(blog);
            })
            .catch(console.error)
    }
    render() {
        return (
            <>
                {Object.keys(this.state.article).length > 0 ?
                    (
                        <>
                            <section className="w-full items-center min-h-50vh mb-0 md:mb-8">
                                <div className="flex w-full gap-10 max-w-6xl mx-auto px-4 sm:px-6">
                                    <div className="w-90-per">
                                        <img
                                            src={'https:' + this.state.article.featuredImage.fields.file.url}
                                            height={this.state.article.featuredImage.fields.file.details.image.height}
                                            width={this.state.article.featuredImage.fields.file.details.image.width}
                                            alt={this.state.article.title}
                                        />
                                    </div>
                                </div>
                            </section>
                            <section className="w-full pt-2 md:pt-8">
                                <div className="flex max-w-6xl mx-auto px-4 sm:px-6 justify-center md:justify-end">
                                    <div className="flex flex-col my-8 w-70-per pr-2 md:pr-28">
                                        <p className="flex gap-4 text-xs font-normal tracking-push subpixel-antialiased text-gray-light uppercase">{this.state.article.tag}</p>
                                        <h3 className="text-green-light font-bold my-2 text-xl md:text-3xl leading-22px subpixel-antialiased whitespace-normal">{this.state.article.title}</h3>
                                        <p className="text-gray-normal font-normal text-base leading-22px subpixel-antialiased whitespace-normal mt-8">
                                            {documentToReactComponents(this.state.article.message)}
                                        </p>
                                    </div>
                                </div>
                            </section>
                        </>
                    ) : ('')
                }
            </>
        );
    }
}

const SinglePage = () => {
    const { slug } = useParams()
    return (
        <>
            <ScrollTop />
            <section className="w-full pt-28 pb-20">
                <div className="flex max-w-6xl mx-auto px-4 sm:px-6 justify-end">
                    <div className="flex flex-col my-8 w-70-per">
                        <h1 className="text-4xl font-black leading-12 subpixel-antialiased text-green-light">The Sustyvibes Blog</h1>
                        <nav className="hidden md:block border-t border-b border-green-light w-full mt-12">
                            <ul className="flex gap-20 py-4 text-green-light">
                                <li className="blog-link"><a>News</a></li>
                                <li className="blog-link"><a>Environment</a></li>
                                <li className="blog-link"><a>Energy</a></li>
                                <li className="blog-link"><a>Women</a></li>
                                <li className="blog-link"><a>Diary</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <ContentFulContents slug={slug} />
        </>
    )
}

export default SinglePage
