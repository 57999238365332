import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import less from './../assets/images/less.png';
import emptyImg from './../assets/images/empty-result_shot.png';
import shop from './../assets/images/shop.png';
import pins from './../assets/images/pins.png';
import ScrollTop from '../components/ScrollTop';
import { directory } from './../directory';
import { ReactComponent as ArrowLine } from './../assets/svg/arrow-line.svg';

function Recycle(props) {
    const [searchValue, setSearchValue] = useState("");
    const [newData, setNewData] = useState([]);
    const [openBox, setOpenBox] = useState(false);
    const handleSearchInputChanges = (e) => {
        setSearchValue(e.target.value);
        // console.log(e.target.value);
        const data = directory.filter((value, index) => value.state.toLowerCase().includes(e.target.value) || value.point.toLowerCase().includes(e.target.value));
        setNewData(data);
        setOpenBox(true)
        if (e.target.value === '') setOpenBox(false);
    }
    return (
        <>
            <ScrollTop />
            {/* we are making section */}
            <section className="w-full bg-green-dark pt-28 pb-20 h-40vh">
                <div className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex flex-col w-full md:w-42rem max-w-2xl mx-auto px-4 sm:px-6">
                        <h1 className="text-2xl md:text-4xl font-black leading-22px md:leading-12 subpixel-antialiased text-green-light">Find a recycling company around you</h1>
                    </div>
                </div>
            </section>
            <section className="relative flex flex-col max-w-6xl w-100vw mx-auto min-h-20vh px-4 sm:px-6 mb-4 md:mb-4">
                <div className="w-100vw absolute -top-12 h-15vh bg-green-light flex justify-center items-center">
                    <form className="w-70-per mx-auto border-b-4 border-green-bright border-opacity-50">
                        <div className="flex justify-between items-center border-b-2 border-green-bright py-4">
                            <input type="email" onChange={handleSearchInputChanges} className="w-100vw bg-transparent outline-none text-green-dark text-base focus:border-0 placeholder-green-dark" placeholder="Search for a state, town or popular landmarks near you" />
                        </div>
                    </form>
                </div>
                {openBox ? (
                    <div className="w-100vw h-60vh bg-white-light absolute top-24 z-10 shadow-md overflow-y-scroll pb-12">
                        {/* ruler */}
                        <hr className="border border-green-light" />
                        <div className="w-70-per mx-auto flex flex-col">
                            {newData.length > 0 ?
                                newData.map((item, index) => (
                                    <NavLink to={'recycle/' + item.id} key={index}><div className="w-100vw flex justify-between items-center border-b-2 border-green-light text-green-light pt-12 pb-4">
                                        <p className="font-bold w-full md:w-50-per">{item.name}</p>
                                        <p className="w-20-per">{item.point}</p>
                                        <p>Pick up & Drop off</p>
                                        <span><ArrowLine /></span>
                                    </div></NavLink>
                                )) : (
                                    <img src={emptyImg} className="w-40-per self-center mt-8" alt="no result" />
                                )}
                        </div>
                    </div>
                ) : ('')}
            </section>
            {/* <section className="flex items-center mb-16">
                <div className="flex flex-col w-52rem max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">similar products</h2>
                    <p className="text-green-light font-bold leading-8 md:leading-40px text-2xl md:text-32px subpixel-antialiased whitespace-normal my-2">You may like these</p>
                </div>
            </section>
            <section className="flex items-center min-h-50vh mb-32">
                <div className="flex flex-col md:flex-row w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-full md:w-30">
                        <div className="relative">
                            <img src={less} className="w-full" alt="less" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">Best seller</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-30">
                        <div className="relative">
                            <img src={shop} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">New arrival</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-30">
                        <div className="relative">
                            <img src={pins} className="w-full" alt="pins" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default Recycle
