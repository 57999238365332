import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Bloghomecard({ item }) {
    let { title, slug, thumbnail, tag } = ''
    let image = '';
    if (item) {
        title = item.fields.title
        slug = item.fields.slug
        thumbnail = item.fields.thumbnail
        tag = item.fields.tag
        image = `https:${thumbnail.fields.file.url}`
    }
    return (
        <>
            <NavLink key={1} to={'/blog/' + slug}><div className="cursor-pointer w-40-per md:w-30 h-55vh border-b border-green-light">
                <div className="relative">
                    <img
                        src={image}
                        alt={title}
                        className="w-full"
                    />
                </div>
                <div className="w-full flex flex-col">
                    <p className="text-gray-normal font-light text-xs subpixel-antialiased whitespace-normal my-4 tracking-push uppercase">{tag}</p>
                    <h3 className="text-green-light font-bold text-18px leading-22px subpixel-antialiased whitespace-normal mb-4">{title}</h3>
                </div>
            </div></NavLink>
        </>
    )
}
