import React, { useEffect } from 'react'

function Volunteer() {
    useEffect(() => {
        window.location.href = "https://docs.google.com/forms/d/1BjgZ21tbTWZv8PRluBwTJBLeojch5akDDRPAHon9lFM/edit?ts=60c63fa9";
    }, []);
    return (
        <>

        </>
    )
}

export default Volunteer;
