/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NavLink } from 'react-router-dom';
import slider from './../assets/images/slider.png';
import downArrow from './../assets/images/arrow-down.svg';
import longArrow from './../assets/images/long-arrow.svg';
import shopImage from './../assets/images/shop.png';
import less from './../assets/images/less.png';
import pins from './../assets/images/pins.png';
import shopBanner from './../assets/images/shop-banner.svg';
import ScrollTop from '../components/ScrollTop';

function shop() {
    return (
        <>
            <ScrollTop />
            {/* we are making section */}
            <section className="w-full bg-green-brighter pt-28 pb-20">
                <div className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex max-w-4xl mx-auto">
                    </div>
                    <div className="flex items-end my-8 max-w-2xl">
                        <img src={downArrow} alt="arrow down" className="mr-8 md:mr-40" />
                        <h1 className="text-2xl md:text-4xl font-black leading-22px md:leading-12 subpixel-antialiased text-green-light">Your search for the ultimate susty marketplace is over</h1>
                    </div>
                </div>
            </section>
            {/* image section */}
            <section className="relative flex max-w-6xl mx-auto min-h-30vh px-4 sm:px-6">
                <div className="absolute -top-20">
                    <img src={slider} alt="Slider" />
                </div>
            </section>
            <section className="flex items-center min-h-40vh">
                <div className="flex flex-col max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-xs md:text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">Susty Living</h2>
                    <p className="text-green-light font-bold leading-22px md:leading-12 text-2xl md:text-3xl subpixel-antialiased whitespace-normal">Shop from our wide range of new arrivals</p>
                </div>
            </section>
            {/* sections */}
            <section className="flex items-center min-h-50vh mb-20">
                <div className="flex flex-wrap w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={shopImage} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">New arrival</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={less} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">Best seller</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={pins} className="w-full" alt="shop" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={shopImage} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">New arrival</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={less} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">Best seller</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={pins} className="w-full" alt="shop" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flex items-center min-h-30vh">
                <div className="flex flex-col max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-xs md:text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">Furniture</h2>
                    <p className="text-green-light font-bold leading-12 text-3xl subpixel-antialiased whitespace-normal">Shop from our wide range of new arrivals</p>
                </div>
            </section>
            {/* sections */}
            <section className="flex items-center min-h-50vh mb-20">
                <div className="flex flex-wrap w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={shopImage} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">New arrival</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={less} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">Best seller</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={pins} className="w-full" alt="shop" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flex items-center min-h-30vh">
                <div className="flex flex-col max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-xs md:text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">Solar Accessories</h2>
                    <p className="text-green-light font-bold leading-12 text-3xl subpixel-antialiased whitespace-normal">Shop from our wide range of new arrivals</p>
                </div>
            </section>
            {/* sections */}
            <section className="flex items-center min-h-50vh mb-20">
                <div className="flex flex-wrap w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={shopImage} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">New arrival</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={less} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">Best seller</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={pins} className="w-full" alt="shop" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flex items-center min-h-30vh">
                <div className="flex flex-col max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-xs md:text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">Fashion</h2>
                    <p className="text-green-light font-bold leading-12 text-3xl subpixel-antialiased whitespace-normal">Shop from our wide range of new arrivals</p>
                </div>
            </section>
            {/* sections */}
            <section className="flex items-center min-h-50vh mb-20">
                <div className="flex flex-wrap w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={shopImage} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">New arrival</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={pins} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">Best seller</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-40-per md:w-30 my-4">
                        <div className="relative">
                            <img src={less} className="w-full" alt="shop" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hidden md:flex items-center mb-32 h-40vh">
                <div className="flex flex-wrap w-full relative gap-10 max-w-6xl mx-auto px-4 sm:px-6 justify-center">
                    <img src={shopBanner} alt="sustyvibes shop banner" />
                    <div className="flex gap-32 absolute max-w-3xl mx-auto h-30vh items-center">
                        <div className="w-60-per">
                            <h4 className="font-bold text-sm md:text-2xl subpixel-antialiased whitespace-normal text-green-bright mb-4">SustyVibes Green Directory</h4>
                            <p className="text-green-bright font-normal text-xs md:text-base leading-20px subpixel-antialiased whitespace-normal">We have curated a list of businesses providing eco-friendly goods and services. You can also find many of their products in the Sustainability Marketplace</p>
                        </div>
                        <NavLink to="/recycle"><a className="flex gap-8"><span className="font-bold text-sm md:text-2xl subpixel-antialiased whitespace-normal text-green-light">Explore</span> <img src={longArrow} alt="long arrow" /></a></NavLink>
                    </div>
                </div>
            </section>
        </>
    )
}

export default shop
