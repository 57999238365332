/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import logo from './../assets/images/footer-logo.svg';
import womanHead from './../assets/images/woman-head.svg';
import confettiImg from './../assets/images/footer-confetti.svg';
import MailChimpFormInput from './MailChimpFormInput';
import { NavLink } from 'react-router-dom';
import { ReactComponent as SubmitArrow } from './../assets/images/submit-arrow.svg';

function Footer() {
    return (
        <>
            <footer className="">
                {/* top footer */}
                <section className="relative min-h-40vh bg-green-light mt-20 pb-20">
                    <div className="flex full-width max-w-4xl justify-start mx-auto px-4 sm:px-6">
                        <img src={womanHead} alt="footer woman head icon" className="absolute -top-12" />
                        <div className="max-w-4xl w-42rem">
                            <p className="text-green-bright font-bold text-2xl leading-32px subpixel-antialiased whitespace-normal mt-20">Add impact to your inbox</p>
                            <p className="text-green-bright font-light text-xs leading-22px subpixel-antialiased whitespace-normal mb-20">News, SustyVibes events, sales, more!</p>

                            <div className="w-full border-b-4 border-green-bright border-opacity-50">
                                <div className="mailchimp flex justify-between items-center border-b-2 border-green-bright py-4">
                                    {/* <input type="email" className="w-80-per bg-transparent outline-none text-green-bright text-xl focus:border-0 placeholder-green-bright lowercase" placeholder="Your email address here" /> <button className="w-10-per flex justify-end outline-none"><SubmitArrow /></button> */}
                                    <MailChimpFormInput />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="flex flex-col md:flex-row min-h-55vh bg-green-light">
                    <div className="relative w-full md:w-30 bg-green-dark">
                        <div className="flex flex-col px-8 md:px-24 pt-8 md:pt-20">
                            <span className="w-60-per"><img src={logo} alt="Sustyvibes Logo" /></span>
                            <p className="text-green-bright font-normal text-xs leading-14px subpixel-antialiased whitespace-normal my-4">We’re a community of young people passionately promoting the message of sustainability through engaging projects and events. </p>
                            <a href="https://flutterwave.com/pay/sustyyvibesqpcv" target="_blank" className="bg-green-light text-sm font-normal text-white-light py-2 px-4 rounded-full text-center" rel="noreferrer">Donate Now</a>
                            <p className="text-green-bright font-normal text-xs subpixel-antialiased whitespace-normal my-8">Contact us: <span className="font-bold">info@sustyvibes.com</span></p>
                        </div>
                        <img src={confettiImg} alt="footer flower" className="absolute bottom-0" />
                    </div>
                    <div className="w-full md:w-70-per bg-green-bright">
                        <div className="flex gap-4 px-8 md:px-24 pt-8 md:pt-20">
                            <div className="w-50-per border-t border-green-light">
                                <p className="uppercase text-green-light font-normal text-xs subpixel-antialiased whitespace-normal my-4">Our Community</p>
                                <a href="https://docs.google.com/forms/d/1BjgZ21tbTWZv8PRluBwTJBLeojch5akDDRPAHon9lFM/edit?ts=60c63fa9" target="_blank" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2" rel="noreferrer">Volunteer with us</a>
                                <NavLink to="/recycle"><a className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">Green Directory</a></NavLink>
                                <a href="https://flutterwave.com/pay/sustyyvibesqpcv" target="_blank" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2" rel="noreferrer">Giving Back</a>
                                {/* <a href="#" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">Our Partners</a> */}
                                {/* <a href="#" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">Code of ethics</a> */}
                                {/* <a href="#" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">Latest campaigns</a> */}
                            </div>
                            <div className="w-50-per flex flex-col">
                                <div className="border-t border-green-light mb-10">
                                    <p className="uppercase text-green-light font-normal text-xs subpixel-antialiased whitespace-normal my-4">Sustyvibes</p>
                                    <NavLink to="/projects"><a className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">Projects</a></NavLink>
                                    <NavLink to="/shop"><a className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">Shop</a></NavLink>
                                    <NavLink to="/about"><a className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">About us</a></NavLink>
                                    <NavLink to="/blog"><a className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2">Blog</a></NavLink>
                                </div>
                                <div className="border-t border-green-light">
                                    <p className="uppercase text-green-light font-normal text-xs subpixel-antialiased whitespace-normal my-4">social media</p>
                                    <a href="https://twitter.com/sustyvibes" target="_blank" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2" rel="noreferrer">Twitter</a>
                                    <a href="https://facebook.com/sustyvibes/" target="_blank" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2" rel="noreferrer">Facebook</a>
                                    <a href="https://instagram.com/sustyvibes/" target="_blank" className="text-gray-normal block font-normal text-xs subpixel-antialiased whitespace-normal my-2" rel="noreferrer">LinkedIn</a>
                                </div>
                            </div>
                        </div>
                        <div className="block px-8 md:px-24 pt-8 md:pt-20">
                            <div className="border-t border-green-light">
                                <p className="text-green-light font-normal text-sm subpixel-antialiased whitespace-normal mt-2 mb-8">2021 Sustyvibes | Terms of Use | Privacy Policy</p>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </>
    )
}

export default Footer
