import React, { Component } from 'react';
// import the component
import Mailchimp from 'react-mailchimp-form'

class MailChimpFormInput extends Component {
    render() {
        return (
            <Mailchimp
                action='https://sustyvibes.us13.list-manage.com/subscribe/post?u=edc6188e6723e4c6fd74c3633&amp;id=eba8e92fa5'
                fields={[
                    {
                        name: 'EMAIL',
                        placeholder: 'Your email address here',
                        type: 'email',
                        required: true,
                        className: 'w-80-per bg-transparent outline-none text-green-bright text-xl focus:border-0 placeholder-green-bright lowercase'
                    }
                ]}
            />
        );
    }
}

export default MailChimpFormInput;