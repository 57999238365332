import React from 'react'
import ScrollTop from '../components/ScrollTop';
import recycle from './../assets/svg/recycle.svg';
import blockImg from './../assets/svg/block.svg';
import { NavLink } from 'react-router-dom';
import { directory } from './../directory';
import { useParams } from 'react-router-dom'
import { ReactComponent as BackArrow } from './../assets/svg/back-arrow.svg';

const RecyclePage = () => {
    const { id } = useParams();
    const data = directory.filter((value, index) => value.id === +id);
    const directoryData = data[0];
    return (
        <>
            <ScrollTop />
            <section className="flex justify-start items-start mt-8 mb-32 min-h-150vh md:min-h-150vh">
                <div className="flex flex-wrap w-full relative max-w-6xl mx-auto px-4 sm:px-6 justify-center">
                    <img src={recycle} alt="recycle banner" />
                    <div className="flex absolute w-100vw max-w-6xl mx-auto pl-12 px-4 sm:px-6 min-h-50vh md:min-h-50vh text-left">
                        <div className="w-100vw flex flex-col pl-8 md:pl-40 pt-8">
                            <NavLink to="/recycle"><div className="flex gap-4 text-green-light"><BackArrow /> Back</div></NavLink>
                            <div className="mt-12">
                                <img src={blockImg} alt="Block" />
                            </div>
                            <div className="flex mt-12 gap-32">
                                <h3 className="text-green-light font-bold my-4 text-base md:text-3xl leading-32px subpixel-antialiased whitespace-normal w-full md:w-40-per">{directoryData.name}</h3>
                                <div>
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase mb-4">Contact Details</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        <span className="">{directoryData.contact.name}</span><br />
                                        <span>{directoryData.contact.web}</span><br />
                                        <span>{directoryData.contact.email}</span><br />
                                        <span>{directoryData.contact.phone}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="flex mt-12 gap-32 border-t border-green-light">
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">Primary address</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.primaryAddress}
                                    </p>
                                </div>
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">State</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.state}
                                    </p>
                                </div>
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">Area of Operation</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.areaOfOperation}
                                    </p>
                                </div>
                            </div>
                            <div className="flex mt-12 gap-32 border-t border-green-light">
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">materials collected</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.materials}
                                    </p>
                                </div>
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">Condition of materials</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.condition}
                                    </p>
                                </div>
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">minimum weight</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.weight}
                                    </p>
                                </div>
                            </div>
                            <div className="flex mt-12 gap-32 border-t border-green-light">
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">mode of collection</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.collection}
                                    </p>
                                </div>
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">dropoff location</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.dropOff}
                                    </p>
                                </div>
                                <div className="w-full md:w-40-per">
                                    <h2 className="text-sm font-normal subpixel-antialiased text-green-light uppercase my-4">incentives</h2>
                                    <p className="text-gray-normal font-light text-base subpixel-antialiased leading-14px whitespace-normal">
                                        {directoryData.incentives}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecyclePage
