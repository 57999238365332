export const directory = [
    {
        "id": 1,
        "name": "African Cleanup Initiative",
        "contact": {
            "name": "Mr Alexander Akhigbe",
            "web": "www.acuinitiative.org",
            "email": "info.acinigeria@gmail.com",
            "phone": "08021349281"
        },
        "point": "Eti-Osa",
        "primaryAddress": "3, Taiwo Odunuga street, Off Oludegun, Ire-akari",
        "state": "Lagos",
        "areaOfOperation": "Lagos",
        "materials": "PET, Sachet water, bottles, cartoons",
        "condition": "Clean",
        "weight": "5kg",
        "collection": "Pickup and drop off",
        "dropOff": "Magodo, Ejigbo, Iyanapaja, Jakande, surulere, Gbagada, Obalende, Ebute-metta",
        "incentives": "Diaper, Pad, value for school fees"
    },
    {
        "id": 2,
        "name": "HISL RECYCLERS ENTERPRISES",
        "contact": {
            "name": "Mr Alexander Akhigbe",
            "web": "www.acuinitiative.org",
            "email": "info.acinigeria@gmail.com",
            "phone": "08021349281"
        },
        "point": "Ibeju-Lekki",
        "primaryAddress": "3, Taiwo Odunuga street, Off Oludegun, Ire-akari",
        "state": "Lagos",
        "areaOfOperation": "Lagos",
        "materials": "PET, Sachet water, bottles, cartoons",
        "condition": "Clean",
        "weight": "5kg",
        "collection": "Pickup and drop off",
        "dropOff": "Magodo, Ejigbo, Iyanapaja, Jakande, surulere, Gbagada, Obalende, Ebute-metta",
        "incentives": "Diaper, Pad, value for school fees"
    },
    {
        "id": 3,
        "name": "Solid Chemicals resources LTD",
        "contact": {
            "name": "Mr Alexander Akhigbe",
            "web": "www.acuinitiative.org",
            "email": "info.acinigeria@gmail.com",
            "phone": "08021349281"
        },
        "point": "Ajao Estate",
        "primaryAddress": "3, Taiwo Odunuga street, Off Oludegun, Ire-akari",
        "state": "Lagos",
        "areaOfOperation": "Lagos",
        "materials": "PET, Sachet water, bottles, cartoons",
        "condition": "Clean",
        "weight": "5kg",
        "collection": "Pickup and drop off",
        "dropOff": "Magodo, Ejigbo, Iyanapaja, Jakande, surulere, Gbagada, Obalende, Ebute-metta",
        "incentives": "Diaper, Pad, value for school fees"
    },
    {
        "id": 4,
        "name": "Mega recyclers ltd",
        "contact": {
            "name": "Mr Alexander Akhigbe",
            "web": "www.acuinitiative.org",
            "email": "info.acinigeria@gmail.com",
            "phone": "08021349281"
        },
        "point": "Surulere",
        "primaryAddress": "3, Taiwo Odunuga street, Off Oludegun, Ire-akari",
        "state": "Lagos",
        "areaOfOperation": "Lagos",
        "materials": "PET, Sachet water, bottles, cartoons",
        "condition": "Clean",
        "weight": "5kg",
        "collection": "Pickup and drop off",
        "dropOff": "Magodo, Ejigbo, Iyanapaja, Jakande, surulere, Gbagada, Obalende, Ebute-metta",
        "incentives": "Diaper, Pad, value for school fees"
    },
    {
        "id": 5,
        "name": "Street Waste Company Limited",
        "contact": {
            "name": "Mr Alexander Akhigbe",
            "web": "www.acuinitiative.org",
            "email": "info.acinigeria@gmail.com",
            "phone": "08021349281"
        },
        "point": "Yaba",
        "primaryAddress": "3, Taiwo Odunuga street, Off Oludegun, Ire-akari",
        "state": "Lagos",
        "areaOfOperation": "Lagos",
        "materials": "PET, Sachet water, bottles, cartoons",
        "condition": "Clean",
        "weight": "5kg",
        "collection": "Pickup and drop off",
        "dropOff": "Magodo, Ejigbo, Iyanapaja, Jakande, surulere, Gbagada, Obalende, Ebute-metta",
        "incentives": "Diaper, Pad, value for school fees"
    },
    {
        "id": 6,
        "name": "Green Axis",
        "contact": {
            "name": "Mr Alexander Akhigbe",
            "web": "www.acuinitiative.org",
            "email": "info.acinigeria@gmail.com",
            "phone": "08021349281"
        },
        "point": "Ajah",
        "primaryAddress": "3, Taiwo Odunuga street, Off Oludegun, Ire-akari",
        "state": "Lagos",
        "areaOfOperation": "Lagos",
        "materials": "PET, Sachet water, bottles, cartoons",
        "condition": "Clean",
        "weight": "5kg",
        "collection": "Pickup and drop off",
        "dropOff": "Magodo, Ejigbo, Iyanapaja, Jakande, surulere, Gbagada, Obalende, Ebute-metta",
        "incentives": "Diaper, Pad, value for school fees"
    }
]