import React from 'react'
import highCommission from './../assets/svg/high-commission.svg';
import wecoolers from './../assets/svg/wecoolers.svg';
import civichire from './../assets/svg/civichire.svg';
import crin from './../assets/svg/crin.svg';
import mani from './../assets/svg/mani.svg';
import shapers from './../assets/svg/shapers.svg';
import lufast from './../assets/svg/lufast.svg';
import bernard from './../assets/svg/bernard.svg';

function Partners() {
    return (
        <section className="flex flex-col items-center">
            <div className="flex full-width max-w-4xl justify-start mx-auto px-4 sm:px-6">
                <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4 align-left">our partners</h2>
            </div>
            <div className="full-width max-w-6xl mx-auto my-10 px-4 sm:px-6 flex flex-wrap flex-row gap-8">
                <img src={highCommission} alt="High Commission" />
                <img src={wecoolers} alt="Wecoolers" />
                <img src={civichire} alt="Civic Hire" />
                <img src={crin} alt="Crin" />
                <img src={mani} alt="Mani" />
                <img src={shapers} alt="Global Shapers" />
                <img src={lufast} alt="Lufast" />
                <img src={bernard} alt="Bernard Kazu" />
            </div>
        </section>
    )
}

export default Partners
