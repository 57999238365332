/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Typical from 'react-typical'
import { NavLink } from 'react-router-dom';
import less from './../assets/images/less.png';
import shop from './../assets/images/shop.png';
import pins from './../assets/images/pins.png';
import banner from './../assets/images/shop-banner.svg';
import longArrow from './../assets/images/long-arrow.svg';
import smallArrow from './../assets/images/small-arrow.svg';
import downArrow from './../assets/images/arrow-down.svg';

//components
import Partners from '../components/Partners'
import MobileCarousel from '../components/MobileCarousel'
import CardCarousel from '../components/CardCarousel'
import Bloghomecard from '../components/Bloghomecard';
import ScrollTop from '../components/ScrollTop';

import { client } from './../client';

class ContentFulContents extends React.Component {
    state = {
        articles: []
    }

    componentDidMount() {
        client.getEntries()
            .then((response) => {
                // console.log(response);
                const items = response.items.slice(0, 6);
                this.setState({
                    articles: items
                })
                // console.log(this.state.articles);
            })
            .catch(console.error)
    }
    render() {
        return (
            <>
                {console.log(this.state.articles)}
                {/* {this.state.articles.map((item, index) => (
                    <Bloghomecard item={item} key={index} />
                ))} */}
                {this.state.articles.length > 0 ?
                    (
                        <>
                            {this.state.articles.map((item, index) => (
                                <Bloghomecard item={item} key={index} />
                            ))}
                        </>
                    ) : ('')
                }
            </>
        );
    }
}


const home = () => {
    return (
        <>
            <ScrollTop />
            {/* we are making section */}
            <section className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6 mt-16">
                <h1 className="text-2xl md:text-6xl font-semibold leading-2rem md:leading-5rem subpixel-antialiased text-green-light">We’re making <br />sustainability
                    <Typical
                        steps={[' actionable', 5000, ' fun', 5000, ' relatable', 5000, ' cool', 5000]}
                        loop={Infinity}
                        wrapper="span"
                        className="text-green-dark text-2xl md:text-6xl"
                    />
                    <br />for young Africans</h1>
                <div className="flex mt-16 max-w-4xl">
                    {/* <img src={downArrow} alt="arrow down" className="mr-8 md:mr-40" /> */}
                    <p className="text-gray-normal font-light text-sm md:text-xl leading-20px subpixel-antialiased whitespace-normal">We’re a community of young people passionately promoting the message of sustainability through engaging projects and events. </p>
                </div>
            </section>
            {/* image section */}
            <section className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6 mt-16">
                <img src="https://res.cloudinary.com/dqqrgidob/image/upload/v1623999498/slider_gjn56q.jpg" alt="Slider" />
                {/* <HomeSlider /> */}
            </section>
            {/* vision */}
            <section className="bg-green-brighter flex items-center min-h-50vh">
                <div className="flex flex-col max-w-6xl mx-auto py-10 px-4 sm:px-6">
                    <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase">OUR VISION</h2>
                    <div className="flex md:grid flex-col md:grid-cols-2 my-4 gap-8 md:gap-24">
                        <p className="text-green-light font-bold leading-22px text-2xl md:text-32px md:leading-12 subpixel-antialiased whitespace-normal">We envision a world where doing the right thing for the planet is normalized.</p>
                        <p className="text-gray-normal font-light text-sm md:text-base leading-22px subpixel-antialiased whitespace-normal">
                            We organise events and projects dedicated to discussing how intentional living can become our collective norm. We also actively partner with private and public sector organisations/corporate to support sustainability.
                            <br /><br />
                            We use popular culture as an education tool for older and younger Africans and embark on projects to protect groups most vulnerable to climate degradation.
                        </p>
                    </div>
                </div>
            </section>
            {/* ruler */}
            <div className="flex bg-green-brighter w-full h-20">
                <div className="full-width max-w-6xl mx-auto px-4 sm:px-6">
                    <hr className="border border-green-light" />
                </div>
            </div>
            {/* numbers */}
            <section className="bg-green-brighter flex min-h-40vh">
                <div className="flex flex-col max-w-4xl mx-auto py-10 px-4 sm:px-6 mt-16">
                    <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase">Our work at a glance</h2>
                    <div className="flex flex-col md:grid md:grid-cols-3 my-4 gap-24">
                        <div className="">
                            <p className="text-green-light font-bold leading-12 text-4xl subpixel-antialiased whitespace-normal">300+</p>
                            <p className="text-gray-normal font-light text-tiny leading-16px subpixel-antialiased whitespace-normal">Street Clean ups</p>
                        </div>
                        <div className="">
                            <p className="text-green-light font-bold leading-12 text-4xl subpixel-antialiased whitespace-normal">52+</p>
                            <p className="text-gray-normal font-light text-tiny leading-16px subpixel-antialiased whitespace-normal">Sustainability events across Nigeria</p>
                        </div>
                        <div className="">
                            <p className="text-green-light font-bold leading-12 text-4xl subpixel-antialiased whitespace-normal">300+</p>
                            <p className="text-gray-normal font-light text-tiny leading-16px subpixel-antialiased whitespace-normal">Collaborations with private and public sectors</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* projects */}
            <section className="flex items-center min-h-30vh mt-12">
                <div className="flex flex-col w-52rem max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">pillars</h2>
                    <p className="text-green-light font-bold leading-22px md:leading-12 text-2xl md:text-32px subpixel-antialiased whitespace-normal">Our work is broadly categorized into five pillars that guide our sustainability focus, the projects we handle and our target audience.</p>
                </div>
            </section>
            {/* carousel images */}
            <section className="flex items-center min-h-70vh">
                <div className="hidden md:block w-72 max-w-6xl mx-auto px-4 sm:px-6">
                    <CardCarousel />
                </div>
                <div className="flex md:hidden w-72 max-w-6xl mx-auto px-4 sm:px-6">
                    <MobileCarousel />
                </div>
            </section>
            {/* ruler */}
            <div className="flex w-full h-20 mt-16">
                <div className="full-width max-w-6xl mx-auto px-4 sm:px-6">
                    <hr className="border border-green-light" />
                </div>
            </div>
            {/* <section className="flex items-center h-half">
                <div className="flex flex-col w-52rem max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">shop</h2>
                    <p className="text-green-light font-bold leading-22px md:leading-40px text-2xl md:text-32px subpixel-antialiased whitespace-normal my-2">Save the planet and your bank account.</p>
                    <p className="text-gray-normal font-light text-base md:text-xl leading-22px subpixel-antialiased whitespace-normal">
                        Shop affordable eco-friendly everyday and luxury products  from our susty marketplace.
                    </p>
                    <NavLink to="/shop"><a className="flex items-center gap-4 text-green-light font-sm text-base subpixel-antialiased whitespace-normal mt-10">
                        <p>Visit Shop</p>
                        <img src={smallArrow} alt="arrow right" />
                    </a></NavLink>
                </div>
            </section>
            <section className="flex items-center min-h-50vh mb-20">
                <div className="flex flex-col md:flex-row w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="w-full md:w-30">
                        <div className="relative">
                            <img src={less} className="w-full" alt="less" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">Best seller</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-30">
                        <div className="relative">
                            <img src={shop} className="w-full" alt="shop" />
                            <span className="absolute bg-white-light text-xs text-green-light top-4 left-4 py-1 px-2">New arrival</span>
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-30">
                        <div className="relative">
                            <img src={pins} className="w-full" alt="pins" />
                        </div>
                        <div className="w-full flex flex-col">
                            <h3 className="text-green-light font-bold text-xl subpixel-antialiased whitespace-normal mt-4 mb-2">Label Pins</h3>
                            <p className="text-gray-normal font-light text-base subpixel-antialiased whitespace-normal mb-4">Lapel pins with susty messages</p>
                            <div className="flex border-t border-b border-green-light py-4 gap-4">
                                <span className="text-green-light font-sm text-base subpixel-antialiased whitespace-normal">NGN 3,000</span>
                                <span className="text-gray-normal font-sm text-base subpixel-antialiased whitespace-normal line-through">NGN 5,000</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="hidden md:flex items-center h-50vh mb-8">
                <div className="flex flex-wrap w-full relative gap-10 max-w-6xl mx-auto px-4 sm:px-6 justify-center">
                    <img src={banner} alt="sustyvibes shop banner" />
                    <div className="flex gap-32 absolute max-w-3xl mx-auto h-30vh items-center">
                        <div className="w-60-per">
                            <h4 className="font-bold text-sm md:text-2xl leading-22px subpixel-antialiased whitespace-normal text-green-bright mb-4">Find a recycling company around you</h4>
                            <p className="text-green-bright font-normal text-xs md:text-base subpixel-antialiased whitespace-normal">We have curated a list of recycling companies around you.</p>
                        </div>
                        <NavLink to="/recycle"><a className="flex gap-8"><span className="font-bold text-sm md:text-2xl subpixel-antialiased whitespace-normal text-green-light">Explore</span> <img src={longArrow} alt="long arrow" /></a></NavLink>
                    </div>
                </div>
            </section>
            <section className="flex items-center h-half">
                <div className="flex flex-col w-52rem max-w-4xl mx-auto px-4 sm:px-6">
                    <h2 className="text-sm font-normal tracking-push subpixel-antialiased text-gray-light uppercase mb-4">our blog</h2>
                    <p className="text-green-light font-bold leading-8 md:leading-12 text-2xl md:text-32px subpixel-antialiased whitespace-normal">Read everything Susty</p>
                    <NavLink to="/blog"><a className="flex items-center gap-4 text-green-light font-sm text-base subpixel-antialiased whitespace-normal mt-10">
                        <p>Visit Blog</p>
                        <img src={smallArrow} alt="arrow right" />
                    </a></NavLink>
                </div>
            </section>
            <section className="flex items-center min-h-50vh mb-20">
                <div className="flex flex-col md:flex-row flex-wrap w-full gap-4 max-w-6xl mx-auto px-4 sm:px-6">
                    <ContentFulContents />
                </div>
            </section>
            <Partners />
        </>
    )
}

export default home;
